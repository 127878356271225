import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, message } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const AppointmentBooking = () => {
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [availableTokens, setAvailableTokens] = useState([]);
  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get('https://api.nillq.com/api/appointments/all', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Transform and sort data by date and time
      const transformedData = response.data
        .map((appointment) => ({
          key: appointment._id,
          date: new Date(appointment.date).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          time: `${appointment.timeStart} - ${appointment.timeEnd}`,
          location: appointment.location,
          doctorName: appointment.doctorName,
          tokens: `${appointment.remainingTokens} / ${appointment.numberOfTokens}`,
          appointmentId: appointment._id,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date) || a.time.localeCompare(b.time));

      setAppointments(transformedData);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      message.error('Failed to fetch appointments.');
    }
  };

  const showBookingModal = async (appointmentId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`https://api.nillq.com/api/appointments/${appointmentId}/available-tokens`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.availableTokens.length === 0) {
        message.warning('No available tokens for this appointment.');
      } else {
        setAvailableTokens(response.data.availableTokens);
        setSelectedAppointment(appointmentId);
        setIsBookingModalVisible(true);
      }
    } catch (error) {
      console.error('Error fetching available tokens:', error);
      message.error('Failed to fetch available tokens.');
    }
  };

  const handleBookAppointment = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('jwtToken');

      // Generate a random userId using UUID
      const userId = uuidv4();

      const bookingData = {
        userId, // Assign the generated random userId
        appointmentId: selectedAppointment,
        tokenNumber: values.tokenNumber,
        name: values.name,
        age: values.age,
        sex: values.sex,
        disease: values.disease,
      };

      await axios.post('https://api.nillq.com/api/appointments/book', bookingData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Booking created successfully');
      setIsBookingModalVisible(false);
      form.resetFields();
      fetchAppointments(); // Refresh appointments to update remaining tokens
    } catch (error) {
      console.error('Error booking appointment:', error);
      message.error('Failed to book appointment.');
    }
  };

  const handleCancelBooking = () => {
    setIsBookingModalVisible(false);
    form.resetFields();
  };

  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">Available Appointments</h3>
      <Table
        columns={[
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
          },
          {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
          },
          {
            title: 'Doctor',
            dataIndex: 'doctorName',
            key: 'doctorName',
          },
          {
            title: 'Tokens',
            dataIndex: 'tokens',
            key: 'tokens',
          },
          {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Button type="primary" onClick={() => showBookingModal(record.appointmentId)}>
                Book
              </Button>
            ),
          },
        ]}
        dataSource={appointments}
        pagination={{ pageSize: 10 }}
      />

      <Modal
        title="Book Appointment"
        visible={isBookingModalVisible}
        onOk={handleBookAppointment}
        onCancel={handleCancelBooking}
        okText="Book"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="tokenNumber"
            label="Select Token"
            rules={[{ required: true, message: 'Please select a token number' }]}
          >
            <Select placeholder="Select token number">
              {availableTokens.map((tokenNumber) => (
                <Select.Option key={tokenNumber} value={tokenNumber}>
                  Token {tokenNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="age"
            label="Age"
            rules={[{ required: true, message: 'Please enter your age' }]}
          >
            <Input type="number" placeholder="Age" />
          </Form.Item>
          <Form.Item
            name="sex"
            label="Sex"
            rules={[{ required: true, message: 'Please select your sex' }]}
          >
            <Select placeholder="Select sex">
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="disease"
            label="Disease"
            rules={[{ required: true, message: 'Please enter the disease' }]}
          >
            <Input placeholder="Disease" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AppointmentBooking;
