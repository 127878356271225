import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, DatePicker, TimePicker, message } from 'antd';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

const { Option } = Select;

const AppointmentsManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [appointments, setAppointments] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [hospitalNames, setHospitalNames] = useState({});
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    const storedAddresses = JSON.parse(localStorage.getItem('addresses'));
    if (storedAddresses) {
      setAddresses(storedAddresses);
      fetchAppointments(storedAddresses);
    } else {
      fetchAddresses().then(fetchedAddresses => {
        localStorage.setItem('addresses', JSON.stringify(fetchedAddresses));
        fetchAppointments(fetchedAddresses);
      });
    }
  }, []);

  const fetchAppointments = async (addresses) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(`https://api.nillq.com/api/appointments/doctor?doctor_id=${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transformedData = await transformAppointments(response.data, addresses);
      setAppointments(transformedData);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      message.error('Failed to fetch appointments.');
    }
  };

  const fetchHospitalName = async (hospitalId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`https://api.nillq.com/api/hospital/name?hospital_id=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.name;
    } catch (error) {
      console.error('Error fetching hospital name:', error);
      return 'Unknown Hospital';
    }
  };

  const transformAppointments = async (data, addresses) => {
    const hospitalIds = [...new Set(data.map(appt => appt.hospitalId).filter(id => id !== null))];
    const hospitalNamesMap = { ...hospitalNames };

    for (const id of hospitalIds) {
      if (!hospitalNamesMap[id]) {
        hospitalNamesMap[id] = await fetchHospitalName(id);
      }
    }
    
    setHospitalNames(hospitalNamesMap);

    return data.map((appointment) => {
      const address = addresses.find((addr) => addr._id === appointment.addressId);
      const timeStart = new Date(`1970-01-01T${appointment.timeStart}:00`);
      const timeEnd = new Date(`1970-01-01T${appointment.timeEnd}:00`);
      const timeDifference = (timeEnd - timeStart) / 60000; // Time difference in minutes
      const timePerPatient = timeDifference / appointment.numberOfTokens;

      return {
        key: appointment._id,
        date: new Date(appointment.date).toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        timeStart: format(timeStart, 'hh:mm a'), // Format time to 12-hour IST
        timeEnd: format(timeEnd, 'hh:mm a'),     // Format time to 12-hour IST
        timePerPatient: timePerPatient.toFixed(2),
        address: address
          ? `${address.location}, ${address.address_line1}, ${address.city}, ${address.state}, ${address.postal_code}`
          : 'Address not found',
        numberOfTokens: appointment.numberOfTokens,
        remainingTokens: appointment.remainingTokens,
        hospitalId: appointment.hospitalId,
        isComplete: appointment.isComplete,
        isCancelled: appointment.isCancelled
      };
    }).filter(appointment => !appointment.isComplete && !appointment.isCancelled); // Only show incomplete and not cancelled appointments
  };

  const fetchAddresses = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(`https://api.nillq.com/api/addresses?doctor_id=${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddresses(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching addresses:', error);
      message.error('Failed to fetch addresses.');
      return [];
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      let addressId = selectedAddress;

      if (selectedAddress === 'add_new') {
        const newAddressDetails = {
          doctor_id: doctorId,
          location: values.newAddressDetails.location || '',
          address_line1: values.newAddressDetails.address_line1 || '',
          address_line2: values.newAddressDetails.address_line2 || '',
          city: values.newAddressDetails.city || '',
          state: values.newAddressDetails.state || '',
          postal_code: values.newAddressDetails.postal_code || '',
          country: 'India', // Assuming India is default
        };

        // Add new address first
        const newAddressResponse = await axios.post(
          'https://api.nillq.com/api/addresses',
          newAddressDetails,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        addressId = newAddressResponse.data.addressId;
        const updatedAddresses = await fetchAddresses(); // Refresh addresses after adding a new one
        localStorage.setItem('addresses', JSON.stringify(updatedAddresses));
      }

      const appointmentData = {
        doctorId,
        date: values.date.format('YYYY-MM-DD'),
        timeStart: values.timeStart.format('HH:mm'),
        timeEnd: values.timeEnd.format('HH:mm'),
        addressId,
        numberOfTokens: values.numberOfTokens,
      };

      await axios.post('https://api.nillq.com/api/appointments/create', appointmentData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Appointment created successfully');
      const updatedAddresses = await fetchAddresses(); // Refresh addresses for appointments transformation
      fetchAppointments(updatedAddresses);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error creating appointment:', error);
      message.error('Failed to create appointment.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleCancelAppointment = async () => {
    if (!selectedAppointment) return;

    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(`https://api.nillq.com/api/appointments/${selectedAppointment.key}/cancel`, {
        cancelReason,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Appointment canceled successfully');
      const updatedAddresses = await fetchAddresses();
      fetchAppointments(updatedAddresses);
      setIsCancelModalVisible(false);
    } catch (error) {
      console.error('Error canceling appointment:', error);
      message.error('Failed to cancel appointment.');
    }
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const handleCancelModal = (appointment) => {
    setSelectedAppointment(appointment);
    setIsCancelModalVisible(true);
  };

  // Disable past dates in DatePicker
  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">Appointments Management</h3>

      {/* Create Appointment Section */}
      <div className="mb-6">
        <Button type="primary" onClick={showModal}>
          Create Appointment
        </Button>
        <Modal
          title="Create Appointment"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Create"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker className="w-full" disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              name="timeStart"
              label="Start Time"
              rules={[{ required: true, message: 'Please select a start time' }]}
            >
              <TimePicker use12Hours format="h:mm a" minuteStep={15} className="w-full" />
            </Form.Item>
            <Form.Item
              name="timeEnd"
              label="End Time"
              rules={[{ required: true, message: 'Please select an end time' }]}
            >
              <TimePicker use12Hours format="h:mm a" minuteStep={15} className="w-full" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please select or enter an address' }]}
            >
              <Select
                placeholder="Select or type address"
                onChange={(value) => setSelectedAddress(value)}
              >
                {addresses.map((addr) => (
                  <Option key={addr._id} value={addr._id}>
                    {`${addr.location}, ${addr.address_line1}, ${addr.city}, ${addr.state}, ${addr.postal_code}`}
                  </Option>
                ))}
                <Option value="add_new">Add New Address</Option>
              </Select>
            </Form.Item>
            {selectedAddress === 'add_new' && (
              <>
                <Form.Item
                  name={['newAddressDetails', 'location']}
                  label="Location"
                  rules={[{ required: true, message: 'Please enter the location' }]}
                >
                  <Input placeholder="Location" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'address_line1']}
                  label="Address Line 1"
                  rules={[{ required: true, message: 'Please enter the address line 1' }]}
                >
                  <Input placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'address_line2']}
                  label="Address Line 2"
                >
                  <Input placeholder="Address Line 2" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'city']}
                  label="City"
                  rules={[{ required: true, message: 'Please enter the city' }]}
                >
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'state']}
                  label="State"
                  rules={[{ required: true, message: 'Please enter the state' }]}
                >
                  <Input placeholder="State" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'postal_code']}
                  label="Postal Code"
                  rules={[{ required: true, message: 'Please enter the postal code' }]}
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="numberOfTokens"
              label="Number of Tokens"
              rules={[{ required: true, message: 'Please enter the number of tokens' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Form>
        </Modal>
      </div>

      {/* Appointments Table Section */}
      <div>
        <h4 className="text-lg font-semibold mb-2">Appointments Created by You</h4>
        <Table
          columns={[
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: 'Time Start',
              dataIndex: 'timeStart',
              key: 'timeStart',
            },
            {
              title: 'Time End',
              dataIndex: 'timeEnd',
              key: 'timeEnd',
            },
            {
              title: 'Time Per Patient (mins)',
              dataIndex: 'timePerPatient',
              key: 'timePerPatient',
            },
            {
              title: 'Address',
              dataIndex: 'address',
              key: 'address',
            },
            {
              title: 'Number of Tokens',
              dataIndex: 'numberOfTokens',
              key: 'numberOfTokens',
            },
            {
              title: 'Remaining Tokens',
              dataIndex: 'remainingTokens',
              key: 'remainingTokens',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => (
                <Button type="link" onClick={() => handleCancelModal(record)}>
                  Cancel
                </Button>
              ),
            },
          ]}
          dataSource={appointments.filter(appt => !appt.hospitalId)}
          pagination={{ pageSize: 5 }}
        />

        {/* Appointments by Hospital */}
        {Object.keys(hospitalNames).map(hospitalId => (
          <div key={hospitalId} className="mt-8">
            <h4 className="text-lg font-semibold mb-2">Appointments Created by {hospitalNames[hospitalId]}</h4>
            <Table
              columns={[
                {
                  title: 'Date',
                  dataIndex: 'date',
                  key: 'date',
                },
                {
                  title: 'Time Start',
                  dataIndex: 'timeStart',
                  key: 'timeStart',
                },
                {
                  title: 'Time End',
                  dataIndex: 'timeEnd',
                  key: 'timeEnd',
                },
                {
                  title: 'Time Per Patient (mins)',
                  dataIndex: 'timePerPatient',
                  key: 'timePerPatient',
                },
                {
                  title: 'Address',
                  dataIndex: 'address',
                  key: 'address',
                },
                {
                  title: 'Number of Tokens',
                  dataIndex: 'numberOfTokens',
                  key: 'numberOfTokens',
                },
                {
                  title: 'Remaining Tokens',
                  dataIndex: 'remainingTokens',
                  key: 'remainingTokens',
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: (_, record) => (
                    <Button type="link" onClick={() => handleCancelModal(record)}>
                      Cancel
                    </Button>
                  ),
                },
              ]}
              dataSource={appointments.filter(appt => appt.hospitalId === hospitalId)}
              pagination={{ pageSize: 5 }}
            />
          </div>
        ))}
      </div>

      {/* Cancel Appointment Modal */}
      <Modal
        title="Cancel Appointment"
        visible={isCancelModalVisible}
        onOk={handleCancelAppointment}
        onCancel={() => setIsCancelModalVisible(false)}
        okText="Cancel Appointment"
        cancelText="Back"
      >
        <Form layout="vertical">
          <Form.Item
            label="Reason for Cancellation"
            rules={[{ required: true, message: 'Please provide a reason for cancellation' }]}
          >
            <Input.TextArea rows={4} value={cancelReason} onChange={handleCancelReasonChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AppointmentsManagement;
