import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, List, Card } from 'antd';

const DashboardHome = () => {
  const [appointments, setAppointments] = useState([]);
  const [addresses, setAddresses] = useState({});

  useEffect(() => {
    fetchTodaysAppointments();
  }, []);

  const fetchTodaysAppointments = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');

      const response = await axios.get(`https://api.nillq.com/api/appointments/today`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { doctorId: doctorId }
      });

      const appointmentsWithTime = response.data.map(app => ({
        ...app,
        time: `${moment(app.timeStart, 'HH:mm').format('hh:mm A')} - ${moment(app.timeEnd, 'HH:mm').format('hh:mm A')}`
      }));

      setAppointments(appointmentsWithTime);

      // Fetch addresses for all appointments
      const addressPromises = appointmentsWithTime.map(app => fetchAddress(app.addressId, token));
      const addressData = await Promise.all(addressPromises);

      // Map address data by addressId for easy lookup
      const addressMap = addressData.reduce((acc, address) => {
        acc[address._id] = address;
        return acc;
      }, {});

      setAddresses(addressMap);
    } catch (error) {
      console.error('Error fetching today\'s appointments:', error);
      message.error('Failed to fetch today\'s appointments.');
    }
  };

  const fetchAddress = async (addressId, token) => {
    try {
      const response = await axios.get(`https://api.nillq.com/api/address/${addressId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching address for ${addressId}:`, error);
      return { _id: addressId, error: true };
    }
  };

  return (
    <div className="p-6 space-y-6">
      <h3 className="text-3xl font-semibold mb-6">Doctor Dashboard</h3>

      <Card title="Upcoming Appointments" bordered={false} className="shadow-md">
        {appointments.length > 0 ? (
          <List
            itemLayout="vertical"
            dataSource={appointments}
            renderItem={(session) => {
              const address = addresses[session.addressId] || {};
              return (
                <List.Item>
                  <List.Item.Meta
                    title={<span className="font-bold">{session.time}</span>}
                    description={
                      <div>
                        <p>Tokens Remaining: <span className="font-semibold">{session.remainingTokens}</span> / {session.numberOfTokens}</p>
                        <p>Address: {address.error ? "Address not found" : `${address.address_line1}, ${address.city}, ${address.state}`}</p>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        ) : (
          <p>No upcoming appointments for today.</p>
        )}
      </Card>
    </div>
  );
};

export default DashboardHome;
