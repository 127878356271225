import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Topbar = () => {
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(`https://api.nillq.com/api/doctor/profile?doctor_id=${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { profilePic, name } = response.data;
      setProfilePic(profilePic);
      setName(name);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  return (
    <header className="bg-gray-800 text-white shadow-md py-4 px-6 flex justify-between items-center">
      <h2 className="text-xl font-semibold">Doctor Dashboard</h2>
      <div className="flex items-center space-x-4">
        <i className="fas fa-bell text-lg cursor-pointer hover:text-gray-400"></i>
        <i className="fas fa-cog text-lg cursor-pointer hover:text-gray-400"></i>
        <div className="relative flex items-center space-x-2">
          <span>{name}</span>
          <img
            src={profilePic || 'https://via.placeholder.com/40'} // Default placeholder
            alt="Profile"
            className="w-10 h-10 rounded-full cursor-pointer hover:ring-2 hover:ring-gray-500 transition-all"
          />
        </div>
      </div>
    </header>
  );
}

export default Topbar;
