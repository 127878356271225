import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message } from 'antd'; // Import the message component from antd

const ProfileManagement = () => {
  const [profilePic, setProfilePic] = useState('');
  const [newPic, setNewPic] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState({
    street: '',
    locality: '',
    city: '',
    state: '',
    pinCode: '',
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(`https://api.nillq.com/api/doctor/profile?doctor_id=${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { name, email, phone, specialization, dob, address, profilePic } = response.data;
      setName(name);
      setEmail(email);
      setPhone(phone);
      setSpecialization(specialization);
      setDob(dob);
      setAddress(address);
      setProfilePic(profilePic);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handlePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewPic(file);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('specialization', specialization);
      formData.append('dob', dob);
      formData.append('street', address.street);
      formData.append('locality', address.locality);
      formData.append('city', address.city);
      formData.append('state', address.state);
      formData.append('pinCode', address.pinCode);

      if (newPic) {
        formData.append('profilePic', newPic);
      }

      await axios.put(`https://api.nillq.com/api/doctor/profile?doctor_id=${doctorId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (newPic) {
        setProfilePic(URL.createObjectURL(newPic)); // Update the profile picture preview
      }

      message.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Profile Management</h3>
      <div className="flex flex-col items-center">
        <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-200 mb-4">
          <img
            src={newPic ? URL.createObjectURL(newPic) : profilePic}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handlePicChange}
          className="mb-6"
        />
      </div>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">Specialization</label>
            <input
              type="text"
              value={specialization}
              onChange={(e) => setSpecialization(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
            <input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
          <input
            type="text"
            placeholder="Street"
            value={address.street}
            onChange={(e) => setAddress({ ...address, street: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
          />
          <input
            type="text"
            placeholder="Locality"
            value={address.locality}
            onChange={(e) => setAddress({ ...address, locality: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
          />
          <input
            type="text"
            placeholder="City"
            value={address.city}
            onChange={(e) => setAddress({ ...address, city: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
          />
          <input
            type="text"
            placeholder="State"
            value={address.state}
            onChange={(e) => setAddress({ ...address, state: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
          />
          <input
            type="text"
            placeholder="Pin Code"
            value={address.pinCode}
            onChange={(e) => setAddress({ ...address, pinCode: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileManagement;
