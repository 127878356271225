import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css'; 

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the JWT token from localStorage
    localStorage.removeItem('jwtToken');
    // Redirect to the sign-in page
    navigate('/signin');
  };

  return (
    <div className="w-64 bg-gray-800 text-white shadow-md flex flex-col h-full">
      <div className="p-6 bg-gray-900 flex items-center">
        <i className="fas fa-user-md fa-2x"></i>
        <h1 className="text-2xl font-semibold ml-3">Doctor Dashboard</h1>
      </div>
      <nav className="flex-1 mt-4">
        <NavLink
          to="/dashboard-home"
          className={({ isActive }) =>
            `block py-3 px-6 text-gray-300 hover:bg-gray-700 transition-colors ${isActive ? 'bg-gray-700 text-white' : ''}`
          }
        >
          <i className="fas fa-tachometer-alt mr-2"></i> Dashboard Home
        </NavLink>
        <NavLink
          to="/appointments-management"
          className={({ isActive }) =>
            `block py-3 px-6 text-gray-300 hover:bg-gray-700 transition-colors ${isActive ? 'bg-gray-700 text-white' : ''}`
          }
        >
          <i className="fas fa-calendar-alt mr-2"></i> Appointments
        </NavLink>
        <NavLink
          to="/patient-details"
          className={({ isActive }) =>
            `block py-3 px-6 text-gray-300 hover:bg-gray-700 transition-colors ${isActive ? 'bg-gray-700 text-white' : ''}`
          }
        >
          <i className="fas fa-user-injured mr-2"></i> Patient Details
        </NavLink>
        <NavLink
          to="/profile-management"
          className={({ isActive }) =>
            `block py-3 px-6 text-gray-300 hover:bg-gray-700 transition-colors ${isActive ? 'bg-gray-700 text-white' : ''}`
          }
        >
          <i className="fas fa-user-cog mr-2"></i> Profile Management
        </NavLink>
        <NavLink
          to="/hospital-management"
          className={({ isActive }) =>
            `block py-3 px-6 text-gray-300 hover:bg-gray-700 transition-colors ${isActive ? 'bg-gray-700 text-white' : ''}`
          }
        >
          <i className="fas fa-hospital mr-2"></i> Hospital Management
        </NavLink>
      </nav>
      <div className="p-6 border-t border-gray-700">
        <button
          onClick={handleLogout}
          className="w-full text-left py-3 px-6 text-gray-300 hover:bg-red-700 transition-colors bg-red-600 rounded-md shadow-md"
        >
          <i className="fas fa-sign-out-alt mr-2"></i> Logout
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
