import React, { useState, useEffect } from 'react';
import { Modal, Button, List, message, Card, Tag } from 'antd';
import axios from 'axios';
import moment from 'moment';

const PatientDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [currentPatientIndex, setCurrentPatientIndex] = useState(0);
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const [patients, setPatients] = useState([]);
  const [addresses, setAddresses] = useState({});
  const [hospitalNames, setHospitalNames] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      if (!doctorId) {
        message.error('Invalid doctor ID');
        return;
      }

      const response = await axios.get(
        `https://api.nillq.com/api/appointments/doctor?doctor_id=${doctorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sortedAppointments = response.data
        .map((appointment) => ({
          ...appointment,
          date: new Date(appointment.date.$date || appointment.date),
        }))
        .sort((a, b) => a.date - b.date || a.timeStart.localeCompare(b.timeStart));

      setAppointments(sortedAppointments);

      const hospitalPromises = sortedAppointments
        .filter((appt) => appt.hospitalId)
        .map((appt) => fetchHospitalName(appt.hospitalId, token));

      const hospitals = await Promise.all(hospitalPromises);

      const hospitalMap = hospitals.reduce((acc, hospital) => {
        if (hospital) acc[hospital._id] = hospital.name;
        return acc;
      }, {});

      setHospitalNames(hospitalMap);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      message.error('Failed to fetch appointments.');
    }
  };

  const fetchHospitalName = async (hospitalId, token) => {
    try {
      const response = await axios.get(`https://api.nillq.com/api/hospital/name?hospital_id=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hospital name:', error);
      return null;
    }
  };

  const fetchPatients = async (appointmentId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      if (!appointmentId) {
        message.error('Invalid appointment ID');
        return;
      }

      const response = await axios.get(
        `https://api.nillq.com/api/appointments/bookings?appointmentId=${appointmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPatients(response.data.filter((patient) => !patient.isComplete && !patient.isMissing));
    } catch (error) {
      console.error('Error fetching patients:', error);
      message.error('Failed to fetch patient details.');
    }
  };

  const fetchAddressDetails = async (addressId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `https://api.nillq.com/api/address/${addressId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAddresses((prevAddresses) => ({
        ...prevAddresses,
        [addressId]: response.data,
      }));
    } catch (error) {
      console.error('Error fetching address details:', error);
      message.error('Failed to fetch address details.');
    }
  };

  const handleAppointmentSelect = (appointment) => {
    const currentTime = moment();
    const sessionStart = moment(appointment.timeStart, 'HH:mm');
    const sessionEnd = moment(appointment.timeEnd, 'HH:mm');

    if (currentTime.isBetween(sessionStart, sessionEnd)) {
      setSelectedAppointment(appointment);
      setCurrentPatientIndex(0);
      setIsSessionCompleted(false);
      fetchPatients(appointment._id);
      fetchAddressDetails(appointment.addressId);
      setIsModalOpen(false);
      setTimeLeft(sessionEnd.diff(currentTime, 'minutes'));
    } else {
      Modal.confirm({
        title: 'Confirm Appointment Selection',
        content: 'This session is not currently active. Do you still want to proceed?',
        onOk: () => {
          setSelectedAppointment(appointment);
          setCurrentPatientIndex(0);
          setIsSessionCompleted(false);
          fetchPatients(appointment._id);
          fetchAddressDetails(appointment.addressId);
          setIsModalOpen(false);
          setTimeLeft(sessionEnd.diff(sessionStart, 'minutes')); // Set total time instead of remaining time since it's outside the session
        },
      });
    }
  };

  const formatDateAndTime = (date, timeStart, timeEnd) => {
    const formattedDate = moment(date).format('MMM D, YYYY');
    const formattedTimeStart = moment(timeStart, 'HH:mm').format('hh:mm A');
    const formattedTimeEnd = moment(timeEnd, 'HH:mm').format('hh:mm A');
    return `${formattedDate}, ${formattedTimeStart} - ${formattedTimeEnd}`;
  };

  const handleMarkAsCompleted = async () => {
    const currentPatient = patients[currentPatientIndex];
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.put(
        `https://api.nillq.com/api/bookings/${currentPatient._id}/complete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Patient marked as complete');

      if (currentPatientIndex === patients.length - 1) {
        await checkMissingPatients(selectedAppointment._id);
      } else {
        setCurrentPatientIndex(currentPatientIndex + 1);
      }
    } catch (error) {
      console.error('Error updating patient status:', error);
      message.error('Failed to update patient status.');
    }
  };

  const handleMarkAsMissing = async () => {
    const currentPatient = patients[currentPatientIndex];
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.put(
        `https://api.nillq.com/api/bookings/${currentPatient._id}/missing`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Patient marked as missing');

      if (currentPatientIndex === patients.length - 1) {
        await checkMissingPatients(selectedAppointment._id);
      } else {
        setCurrentPatientIndex(currentPatientIndex + 1);
      }
    } catch (error) {
      console.error('Error updating patient status:', error);
      message.error('Failed to update patient status.');
    }
  };

  const checkMissingPatients = async (appointmentId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `https://api.nillq.com/api/appointments/${appointmentId}/missing-bookings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        Modal.confirm({
          title: 'Confirm Completion',
          content: (
            <div>
              <p>There are missing patients. Do you want to mark the appointment as complete?</p>
              <List
                dataSource={response.data}
                renderItem={(patient) => (
                  <List.Item>
                    {patient.name} - Token: {patient.tokenNumber}
                  </List.Item>
                )}
              />
            </div>
          ),
          onOk: async () => {
            await updateAppointmentStatus(appointmentId, true);
            setIsSessionCompleted(true);
            message.success('Appointment marked as complete with missing patients');
          },
        });
      } else {
        await updateAppointmentStatus(appointmentId, true);
        setIsSessionCompleted(true);
        message.success('Appointment marked as complete');
      }
    } catch (error) {
      console.error('Error checking missing patients:', error);
      message.error('Failed to check missing patients.');
    }
  };

  const updateAppointmentStatus = async (appointmentId, status) => {
    try {
      const token = localStorage.getItem('jwtToken');
      await axios.put(
        `https://api.nillq.com/api/appointments/${appointmentId}/complete`,
        { isComplete: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Appointment status updated successfully');
    } catch (error) {
      console.error('Error updating appointment status:', error);
      message.error('Failed to update appointment status.');
    }
  };

  const currentPatient = patients[currentPatientIndex];

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-3xl font-semibold mb-6">Patient Details</h3>

      <div className="mb-4">
        <h4 className="text-lg font-semibold mb-2">Select Appointment</h4>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Select Appointment
        </Button>
        {selectedAppointment && (
          <Card className="mt-4 bg-gray-100 shadow-sm">
            <p className="text-lg">
              <strong>Session:</strong> {hospitalNames[selectedAppointment.hospitalId] || 'Doctor Session'}
            </p>
            <p className="text-lg">
              <strong>Date & Time:</strong> {formatDateAndTime(selectedAppointment.date, selectedAppointment.timeStart, selectedAppointment.timeEnd)}
            </p>
            <p className="text-lg">
              <strong>Address:</strong> {addresses[selectedAppointment.addressId]?.location}, {addresses[selectedAppointment.addressId]?.address_line1}, {addresses[selectedAppointment.addressId]?.city}, {addresses[selectedAppointment.addressId]?.state}, {addresses[selectedAppointment.addressId]?.postal_code}
            </p>
            <p className="text-lg">
              <strong>Token Count:</strong> {selectedAppointment.remainingTokens} / {selectedAppointment.numberOfTokens}
            </p>
            {timeLeft && (
              <p className="text-lg text-blue-600 font-semibold">
                Time Left: {timeLeft} minutes
              </p>
            )}
          </Card>
        )}
      </div>

      {isSessionCompleted ? (
        <div className="text-center text-red-600 font-semibold">
          <p>Session Completed!</p>
        </div>
      ) : selectedAppointment && patients.length === 0 ? (
        <div className="text-center text-blue-600 font-semibold">
          <p>No bookings available for this appointment.</p>
          <Button
            type="primary"
            onClick={() => updateAppointmentStatus(selectedAppointment._id, true)}
            className="bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-4"
          >
            Mark Appointment Complete
          </Button>
        </div>
      ) : (
        selectedAppointment && (
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2">Patient Information</h4>
            <div className="p-4 bg-gray-100 rounded-lg">
              <p>
                <strong>Token Number:</strong> {currentPatient?.tokenNumber}
              </p>
              <p>
                <strong>Name:</strong> {currentPatient?.name}
              </p>
              <p>
                <strong>Sex:</strong> {currentPatient?.sex}
              </p>
              <p>
                <strong>Age:</strong> {currentPatient?.age}
              </p>
              <p>
                <strong>Disease:</strong> {currentPatient?.disease}
              </p>
            </div>
          </div>
        )
      )}

      {!isSessionCompleted && patients.length > 0 && selectedAppointment && (
        <div className="text-center space-x-2">
          <Button
            type="primary"
            onClick={handleMarkAsCompleted}
            className="bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            {currentPatientIndex === patients.length - 1
              ? 'Complete Session'
              : 'Mark as Completed'}
          </Button>
          <Button
            type="danger"
            onClick={handleMarkAsMissing}
            className="bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            Mark as Missing
          </Button>
        </div>
      )}

      <Modal
        title="Select Appointment"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={700}
      >
        <List
          itemLayout="vertical"
          dataSource={appointments}
          renderItem={(appointment) => (
            <List.Item>
              <Card
                className="shadow-sm"
                title={<strong>{hospitalNames[appointment.hospitalId] || 'Doctor Session'}</strong>}
                extra={
                  <Tag color={appointment.remainingTokens > 0 ? 'green' : 'red'}>
                    Tokens: {appointment.remainingTokens} / {appointment.numberOfTokens}
                  </Tag>
                }
              >
                <p><strong>Date & Time:</strong> {formatDateAndTime(appointment.date, appointment.timeStart, appointment.timeEnd)}</p>
                <Button type="primary" onClick={() => handleAppointmentSelect(appointment)}>
                  Select
                </Button>
              </Card>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default PatientDetails;
