import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Input, Spin, List } from 'antd';

const HospitalManagement = () => {
  const [associatedHospitals, setAssociatedHospitals] = useState([]);
  const [invitationsFromHospitals, setInvitationsFromHospitals] = useState([]);
  const [requestsToJoinHospitals, setRequestsToJoinHospitals] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssociatedHospitals();
    fetchInvitationsFromHospitals();
    fetchRequestsToJoinHospitals();
  }, []);

  const fetchAssociatedHospitals = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(
        `https://api.nillq.com/api/doctor/associated-hospitals?doctorId=${doctorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAssociatedHospitals(response.data);
    } catch (error) {
      console.error('Error fetching associated hospitals:', error);
      message.error('Failed to fetch associated hospitals.');
    }
  };

  const fetchInvitationsFromHospitals = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(
        `https://api.nillq.com/api/doctor/invitations-from-hospitals?doctorId=${doctorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvitationsFromHospitals(response.data);
    } catch (error) {
      console.error('Error fetching invitations from hospitals:', error);
      message.error('Failed to fetch invitations from hospitals.');
    }
  };

  const fetchRequestsToJoinHospitals = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
      const response = await axios.get(
        `https://api.nillq.com/api/doctor/requests-to-join-hospitals?doctorId=${doctorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestsToJoinHospitals(response.data);
    } catch (error) {
      console.error('Error fetching requests to join hospitals:', error);
      message.error('Failed to fetch requests to join hospitals.');
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `https://api.nillq.com/api/hospitals/search?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching hospitals:', error);
      message.error('Failed to search hospitals.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestJoin = async (hospitalId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');

      await axios.post(
        'https://api.nillq.com/api/doctor/request-join',
        { hospitalId, doctorId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Request to join sent successfully');
      setSearchResults(searchResults.filter((hospital) => hospital._id !== hospitalId));
      fetchRequestsToJoinHospitals();
    } catch (error) {
      console.error('Error requesting to join hospital:', error);
      message.error('Failed to send join request.');
    }
  };

  const handleAcceptInvitation = async (hospitalId) => {
    try {
        const token = localStorage.getItem('jwtToken');
        const doctorId = localStorage.getItem('doctorId');

        await axios.post(
            'https://api.nillq.com/api/doctor/approve-invitation',
            { hospitalId, doctorId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        message.success('Invitation accepted successfully');
        fetchAssociatedHospitals();
        fetchInvitationsFromHospitals();
    } catch (error) {
        console.error('Error accepting invitation:', error.response ? error.response.data : error);
        message.error('Failed to accept invitation.');
    }
};


  const handleRejectInvitation = async (hospitalId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');

      await axios.post(
        'https://api.nillq.com/api/doctor/reject-invitation',
        { hospitalId, doctorId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Invitation rejected');
      setInvitationsFromHospitals(invitationsFromHospitals.filter((inv) => inv.hospitalId !== hospitalId));
    } catch (error) {
      console.error('Error rejecting invitation:', error);
      message.error('Failed to reject invitation.');
    }
  };

  const handleCancelRequest = async (hospitalId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');

      await axios.post(
        'https://api.nillq.com/api/doctor/cancel-request',
        { hospitalId, doctorId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Join request cancelled');
      setRequestsToJoinHospitals(requestsToJoinHospitals.filter((req) => req.hospitalId !== hospitalId));
    } catch (error) {
      console.error('Error cancelling request:', error);
      message.error('Failed to cancel join request.');
    }
  };

  const handleRemoveHospital = async (hospitalId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const doctorId = localStorage.getItem('doctorId');
  
      await axios.post(
        'https://api.nillq.com/api/doctor/remove-hospital',
        { hospitalId, doctorId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      message.success('Hospital removed successfully');
      setAssociatedHospitals(associatedHospitals.filter((hospital) => hospital.hospitalId !== hospitalId));
    } catch (error) {
      console.error('Error removing hospital:', error.response ? error.response.data : error);
      message.error('Failed to remove hospital.');
    }
  };
  

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Hospital Management</h3>

      {/* Search Hospitals Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Search Hospitals</h4>
        <Input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search hospitals by name"
          className="px-4 py-2 border rounded-md w-full"
        />
        {loading ? (
          <div className="flex justify-center items-center py-4">
            <Spin tip="Searching..." />
          </div>
        ) : searchResults.length > 0 ? (
          <List
            className="pt-4"
            itemLayout="horizontal"
            dataSource={searchResults}
            renderItem={(hospital) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleRequestJoin(hospital._id)}
                    className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                  >
                    Request to Join
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={hospital.name}
                  description={`${hospital.address.street}, ${hospital.address.city}`}
                />
              </List.Item>
            )}
          />
        ) : (
          <p className="text-gray-500 pt-4">No hospitals found.</p>
        )}
      </div>

      {/* Associated Hospitals Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Associated Hospitals</h4>
        {associatedHospitals.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={associatedHospitals}
            renderItem={(hospital) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleRemoveHospital(hospital.hospitalId)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Remove
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={hospital.name}
                  description={`${hospital.address.street}, ${hospital.address.city}`}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No associated hospitals.</p>
        )}
      </div>

      {/* Invitations from Hospitals Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Invitations from Hospitals</h4>
        {invitationsFromHospitals.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={invitationsFromHospitals}
            renderItem={(invitation) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleAcceptInvitation(invitation.hospitalId)}
                    className="px-4 py-2 bg-green-600 text-white font-semibold rounded-md shadow-md hover:bg-green-700"
                  >
                    Accept
                  </button>,
                  <button
                    onClick={() => handleRejectInvitation(invitation.hospitalId)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Reject
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={invitation.name}
                  description={`${invitation.address.street}, ${invitation.address.city}`}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No pending invitations.</p>
        )}
      </div>

      {/* Requests to Join Hospitals Section */}
      <div>
        <h4 className="text-lg font-semibold mb-4">Requests to Join Hospitals</h4>
        {requestsToJoinHospitals.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={requestsToJoinHospitals}
            renderItem={(request) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleCancelRequest(request.hospitalId)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Cancel Request
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={request.name}
                  description={`${request.address.street}, ${request.address.city}`}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No pending requests.</p>
        )}
      </div>
    </div>
  );
};

export default HospitalManagement;
